<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12" lg="6">
        <AppCard :title="$t('Handle')" card-text-classes="text-center" :prev-route="prevRoute" :loading="pending">
          <div class="text--disabled">
            <p>
              <template v-if="handle.organisation">
                <span class="font-weight-bold">{{ handle.organisation }}</span>
                <br />
              </template>
              <span>{{ handle.firstname }} {{ handle.lastname }}</span>
              <br />
              <span>
                <v-icon v-if="handle.sex === 'FEMALE'">mdi-human-female</v-icon>
                <v-icon v-else-if="handle.sex === 'MALE'">mdi-human-male</v-icon>
              </span>
            </p>
            <p>
              <b>Unique Name:</b><br />
              <span>{{ handle.unique_name }}</span>
            </p>
            <p>
              <b>Handle Type:</b><br />
              <span>{{ handle.type }}</span>
            </p>
            <p>
              <b>Adresse:</b><br />
              <span>{{ handle.street }} {{ handle.number }}</span>
              <br />
              <span>{{ handle.zip }} {{ handle.city }}</span>
              <br />
              <span>{{ handle.country }}</span>
            </p>
            <p>
              <b>E-Mail</b><br />
              <span>{{ handle.email }}</span>
            </p>
            <p>
              <b>Telefonnumer</b><br />
              <span>{{ handle.phone }}</span>
            </p>
            <p>
              <b>Fax</b><br />
              <span>{{ handle.fax }}</span>
            </p>
            <p>
              <b>Client</b><br />
              <span>
                <router-link
                  :to="{
                    name: 'clients-item',
                    params: { id: handle.clients_id },
                  }"
                >
                  <!-- <b v-if="user.client.contact_handle.organisation">
                    {{ user.client.contact_handle.organisation }}
                  </b> -->
                  <!-- {{ user.client.contact_handle.firstname }} {{ user.client.contact_handle.lastname }} -->
                  {{ handle.clients_id }}
                </router-link>
              </span>
            </p>
          </div>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { defineComponent, onBeforeUnmount, computed, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'

export default defineComponent({
  name: 'ViewHandle',
  components: {
    AppCard,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const prevRoute = ref('/Handles')

    // store
    const pending = computed(_ => $store.state.handle.pending)
    const handle = computed(_ => $store.state.handle.handle)
    const getHandle = _ => $store.dispatch('handle/getHandle')
    const clearHandle = _ => $store.dispatch('handle/clearHandle')

    getHandle()
      .then(r => r)
      .catch(error => {
        if (error.response.status === 404) {
          $router.push({ name: 'notFound' })
        }
      })

    onBeforeUnmount(_ => {
      clearHandle()
    })

    return {
      prevRoute,
      pending,
      handle,
    }
  },
})
</script>
